import React, { useState, useCallback, useEffect } from 'react';
import {
  Container,
  Heading,
  HStack,
  Spinner,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Flex,
} from '@chakra-ui/react';

import { getCustomers } from '../../services/apiRequests/customers';

import { AddCustomerModal } from './components/AddCustomerModal';
import { DeleteCustomerModal } from './components/DeleteCustomerModal';
import { EditCustomerModal } from './components/EditCustomerModal';
import CustomCard from '../common/Card';
import DeepBlueButton from '../common/DeepBlueButton';
import { CustomTd } from '../common/CustomTd';
import { CustomTr } from '../common/CustomTr';
import { HeadingPage } from '../common/HeadingPage';
import { PeopleSVG } from '../common/sidebarSVG/people';
import YellowButton from '../common/YellowButton';
import { MdAdd } from 'react-icons/md';
import { CustomTh } from '../common/CustomTh';

export const CustomerList = () => {
  const [isLoading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [deletingCustomer, setDeletingCustomer] = useState<any>(null);
  const [edittingCustomer, setEdittingCustomer] = useState<any>(null);

  const handleGetCustomers = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getCustomers();

      setCustomers(data);

      setLoading(false);
    } catch (error) { }
  }, []);

  useEffect(() => {
    handleGetCustomers();
  }, [handleGetCustomers]);

  return (
    <Box width='100%' height='100%' >
      <Container maxW='container.xl' paddingLeft="0px" paddingRight="0px">
        <Flex align='end' justify='start'>
          <PeopleSVG
            color='#2E5FA3'
            size='26'
            style={{ marginRight: 8 }}
          />
          <HeadingPage>行政系 御客一覧</HeadingPage>
        </Flex>
      </Container>
      <CustomCard
        maxW='container.xl'
        paddingTop='24px'
        paddingBottom='24px'
        marginTop='24px'
      >
        <HStack marginBottom={5} justifyContent='end'>
          <YellowButton onClick={() => setAddModalOpen(true)}>
            <Flex width='100%' height='100%' align='center' justify={'center'}>
              新規作成
              <MdAdd
                size={24}
                style={{
                  marginLeft: 8,
                }}
              />
            </Flex>
          </YellowButton>
        </HStack>

        <TableContainer>
          <Table size='sm' variant='simple' colorScheme='gray'>
            <Thead>
              <Tr>
                <CustomTh>Id</CustomTh>
                <CustomTh>名前</CustomTh>
                <CustomTh>説明</CustomTh>
                <CustomTh>実行</CustomTh>
              </Tr>
            </Thead>
            <Tbody>
              {customers.map((e, i) => {
                return (
                  <CustomTr key={i}>
                    <CustomTd>{e.id}</CustomTd>
                    <CustomTd>{e.name}</CustomTd>
                    <CustomTd>{e.Description}</CustomTd>
                    <CustomTd>
                      <DeepBlueButton
                        onClick={() => setEdittingCustomer(e)}
                        mr={4}
                      >
                        編集
                      </DeepBlueButton>

                      <Button
                        onClick={() => setDeletingCustomer(e)}
                        variant='outline'
                      >
                        削除
                      </Button>
                    </CustomTd>
                  </CustomTr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <AddCustomerModal
          isVisible={isAddModalOpen}
          setVisible={async (isVisible, isReloadData) => {
            setAddModalOpen(isVisible);

            if (isReloadData) {
              setCustomers([]);
              await handleGetCustomers();
            }
          }}
        />
        <DeleteCustomerModal
          isVisible={!!deletingCustomer}
          id={deletingCustomer?.id}
          setVisible={async (isReloadData) => {
            setDeletingCustomer(null);

            if (isReloadData) {
              setCustomers([]);
              await handleGetCustomers();
            }
          }}
        />
        <EditCustomerModal
          isVisible={!!edittingCustomer}
          initData={edittingCustomer}
          setVisible={async (isReloadData) => {
            setEdittingCustomer(null);

            if (isReloadData) {
              setCustomers([]);
              await handleGetCustomers();
            }
          }}
        />
      </CustomCard>
    </Box >
  );
};
