import React from 'react';
import './style.css';

function PrivacyPolicy() {
  return (
    <div className='privacy-policy'>
      <h1>プライバシーポリシー</h1>
      <p>
        株式会社フィールドプロテクト（以下，「当社」といいます。）は，当社の提供する「ルートル（ROUTOR）」（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
      </p>
      <h2>1. 個人情報の定義</h2>
      <p>
        本ポリシーにおける、「個人情報」とは、個人情報の保護に関する法律規定される「個人情報」及び本サービスの利用に係るパスワードを指します。
      </p>
      <h2>2. 個人情報の取得</h2>
      <p>
        当社は、利用目的の達成に必要な範囲で、個人情報を適正に取得し、不正の手段により取得することはありません。
      </p>
      <h2>3. 利用目的</h2>
      <p>(1)当社は、取得した個人情報を以下の目的で利用します。</p>
      <p>(2)本サービスの提供・運営のため</p>
      <p>
        (3)ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
      </p>
      <p>
        (4)ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため
      </p>
      <p>(5)メンテナンス，重要なお知らせなど必要に応じたご連絡のため</p>
      <p>
        (6)利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため
      </p>
      <p>
        (7)ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため
      </p>
      <p>(8)上記の利用目的に付随する目的</p>
      <h2>4. 第三者提供</h2>
      <p>
        当社は、法令に定められた場合を除き、あらかじめ利用者の同意を得ないで、第三者（日本国外にあるも者を含みます。）に個人情報を提供しません。
      </p>
      <h2>5. 開示、訂正、利用停止、削除</h2>
      <p>
        当社は、利用者から個人情報の開示、訂正、利用停止、削除を求められたときは、法令に定められた場合を除き、本人確認の上で、遅滞なく開示を行います。
      </p>
      <h2>6. お問い合わせ窓口</h2>
      <p>
        個人情報の取り扱いに関するお問い合わせは、下記の窓口までお願いいたします。
      </p>
      <p>〒350-1335 埼玉県狭山市柏原2683番地</p>
      <p>株式会社フィールドプロテクト</p>
      <p>04-2952-3931（受付：平日9:00～17:00）</p>
      <h2>7. プライバシーポリシー</h2>
      <p>当社は、必要に応じて、本プライバシーポリシーを変更いたします。</p>
      <p>【2021年10月1日制定】</p>
    </div>
  );
}

export default PrivacyPolicy;
