import * as React from 'react';
import { Box, Text, Image, HStack, VStack, Button, Flex } from '@chakra-ui/react';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { TiLocationArrow, TiDocumentText } from 'react-icons/ti';
import { Draggable } from 'react-beautiful-dnd';
import { ICollectionPoint } from '../../../models/collectionPoint';
import placeholderImage from '../../../assets/placeholder-image.png';

interface CollectionPointListItemProps {
  collectionPoint: ICollectionPoint;
  index: number;
  onEdit: (collectionPoint: ICollectionPoint) => void;
  onDelete: (collectionPoint: ICollectionPoint) => void;
  handleOpenViewOnlyCPModal: () => void;
}

export const CollectionPointListItem = (
  props: CollectionPointListItemProps
) => {
  const {
    collectionPoint,
    index,
    onEdit,
    onDelete,
    setViewport,
    viewport,
    setHighlightMark,
    highlightMark,
    handleOpenViewOnlyCPModal
  } = props;

  const onEditClick = () => onEdit(collectionPoint);
  const onDeleteClick = () => onDelete(collectionPoint);

  const displayImage = collectionPoint?.images &&
    Array.isArray(collectionPoint?.images) &&
    collectionPoint?.images.length > 0 &&
    collectionPoint?.images[0]?.url;

  return (
    <Draggable
      key={collectionPoint.id}
      draggableId={String(collectionPoint.id)}
      index={index}
    >
      {(provided, snapshot) => (
        <Box
          id={`baserouteCp_${collectionPoint.id}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
        >
          <Box
            fontSize='0.8rem'
            width='385px'
            padding='16px'
            paddingLeft="16px"
            borderLeft={
              snapshot.isDragging || highlightMark?.id === collectionPoint?.id
                ? '4px solid red'
                : '4px solid white'
            }
            userSelect='none'
            backgroundColor={
              snapshot.isDragging || highlightMark?.id === collectionPoint?.id
                ? '#F3F5F9'
                : 'white'
            }
            onClick={() => {
              const [lat, lng] = collectionPoint.location.split(',');

              setViewport({
                latitude: Number(lat),
                longitude: Number(lng),
                zoom: viewport.zoom,
              });

              setHighlightMark(collectionPoint);
            }}
          >
            <Flex
              align='center'
              justify={'start'}
              flexWrap='nowrap'
            >
              <Text
                color='#2E5FA3'
                lineHeight='25px'
                fontSize={14}
                marginRight="5px"
                minWidth={'32px'}
                fontWeight='500'
              >
                {collectionPoint.sequence}
              </Text>
              <Image
                height='42px'
                width='56px'
                maxHeight={'42px'}
                maxWidth='56px'
                src={displayImage || placeholderImage}
                alt='image'
                objectFit='contain'
                fontSize='10px'
                backgroundColor='gray.100'
                borderRadius={4}
                display='block'
                marginRight="21px"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenViewOnlyCPModal(collectionPoint)
                }}
              />

              <VStack align='start' flex={1}>
                <HStack>
                  <Text
                    fontSize={12}
                    color={
                      snapshot.isDragging ||
                        highlightMark?.id === collectionPoint?.id
                        ? '#2E5FA3'
                        : '#0A1524'
                    }
                    width='100px'
                    maxWidth={'100px'}
                    overflow='hidden'
                    textOverflow={'ellipsis'}
                    wordBreak='keep-all'
                    whiteSpace={'nowrap'}
                    textAlign='left'
                    fontWeight={'600'}
                  >
                    {collectionPoint.name}{' '}
                  </Text>
                </HStack>
              </VStack>

              <Flex align={'center'} justify='space-between' flexWrap={'nowrap'}>
                <Button
                  border='1px'
                  mr={2}
                  padding='15px'
                  // colorScheme='gray'
                  borderColor='#E2E8F0'
                  borderRadius='20px'
                  lineHeight='4'
                  variant='outline'
                  size='sm'
                  float='left'
                  _active={{
                    borderColor: '#E3E8F0',
                  }}
                  _hover={{
                    borderColor: '#E3E8F0',
                  }}
                  _focus={{
                    borderColor: '#E3E8F0',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditClick();
                  }}
                >
                  編集
                </Button>

                <Button
                  border='1px'
                  marginTop='0px!important'
                  padding='15px'
                  float='left'
                  // colorScheme='gray'
                  borderColor='red'
                  borderRadius='20px'
                  lineHeight='4'
                  variant='outline'
                  size='sm'
                  _active={{
                    borderColor: 'red',
                  }}
                  _hover={{
                    borderColor: 'red',
                    color: 'red',
                  }}
                  _focus={{
                    borderColor: 'red',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteClick();
                  }}
                >
                  削除
                </Button>
                {/* <Button colorScheme='red' size='xs' onClick={onDeleteClick}>*/}
                {/*  /!*<MdDeleteForever />*!/*/}
                {/* </Button>*/}
              </Flex>
            </Flex>
          </Box>
        </Box>
      )}
    </Draggable>
  );
};
