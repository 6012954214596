import { START_LOADING, STOP_LOADING } from './../actionTypes/App';
import { IUser } from '../../models/user';
import { LOGIN, LOGOUT } from '../actionTypes/App';

export const login = (data: IUser) => {
  return {
    type: LOGIN,
    payload: data,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};


export const startLoading = () => {
  return {
    type: START_LOADING,
  };
};

export const stopLoading = () => {
  return {
    type: STOP_LOADING,
  };
};
