import { CUSTOMER_URL } from '../../constants/urls';
import { ICustomer } from '../../models/customer';
import { hirouAxios } from '../httpInstance';

export async function getCustomers(): Promise<ICustomer[]> {
  try {
    const url = CUSTOMER_URL;
    const response = await hirouAxios.get(url);
    return response.data;
  } catch (e) {
    throw Error('Customer API failed');
  }
}

export const addNewCustomer = async ({
  name,
  description,
  code,
}: {
  name: string;
  description: string;
  code: string;
}) => {
  try {
    const url = CUSTOMER_URL;
    const response = await hirouAxios.post(url, { name, description, code });
    return { isOk: true };
  } catch (error) {
    const { data } = error?.response;
    if (data?.code) {
      return {
        isOk: false,
        errors: {
          code: data?.code.join('\n'),
        },
      };
    }
    return { isOk: false };
  }
};

export const deleteCustomer = async (id) => {
  try {
    const url = CUSTOMER_URL + id + '/';
    await hirouAxios.delete(url);
    return { isOk: true };
  } catch (error) {
    return { isOk: false };
  }
};

export const editCustomer = async ({
  id,
  name,
  description,
  code,
}: {
  id: any;
  name: string;
  description: string;
  code: string;
}) => {
  try {
    const url = CUSTOMER_URL + id + '/';
    await hirouAxios.put(url, { name, description, code });
    return { isOk: true };
  } catch (error) {
    const { data } = error?.response;
    if (data?.code) {
      return {
        isOk: false,
        errors: {
          code: data?.code.join('\n'),
        },
      };
    }
    return { isOk: false };
  }
};
