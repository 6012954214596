import React from 'react';

export const BagIcon = (props) => {
  const svgColor: string = props?.color || 'white';

  const style = {
    color: svgColor,
  };

  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        d='M10 10.4685C10 10.4685 10 11.6113 10 12.3435M10 12.3435C10 13.0757 10 14.2185 10 14.2185M10 12.3435C6.25 12.3435 2.5 11.406 2.55744 9.53101M10 12.3435C13.75 12.3435 17.5 11.406 17.4425 9.53101M2.55744 9.53101C2.68566 7.46074 3.00949 5.85602 3.33333 5.57267C3.75 5.20809 7.1875 4.9309 7.1875 4.9309M2.55744 9.53101C2.52068 10.1244 2.5 10.7562 2.5 11.406C2.5 14.3227 2.91666 16.8747 3.33333 17.2393C3.75 17.6039 6.66666 17.9685 10 17.9685C13.3334 17.9685 16.25 17.6039 16.6667 17.2393C17.0834 16.8747 17.5 14.3227 17.5 11.406C17.5 10.7562 17.4793 10.1244 17.4425 9.53101M17.4425 9.53101C17.3144 7.46074 16.9905 5.85602 16.6667 5.57267C16.25 5.20809 12.8125 4.9309 12.8125 4.9309M12.8125 4.9309C11.9487 4.87585 10.9928 4.84351 10 4.84351C9.00719 4.84351 8.05134 4.87585 7.1875 4.9309M12.8125 4.9309V3.90601C12.8125 2.24191 11.2775 2.03101 10 2.03101C8.72247 2.03101 7.1875 2.24191 7.1875 3.90601V4.9309'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke='currentColor'
      />
    </svg>
  );
};
