import React, { useState, useCallback, useEffect } from 'react';
import {
  Container,
  Heading,
  HStack,
  Spinner,
  Flex,
  Button,
  Box,
} from '@chakra-ui/react';
import { getReportTypes } from '../../services/apiRequests/reportTypes';
import { ReportTypeItem } from './components/ReportTypeItem';
import { AddReportTypeModal } from './components/AddReportTypeModal';
import { EditReportTypeModal } from './components/EditReportTypeModal';
import { DeleteReportTypeModal } from './components/DeleteReportTypeModal';
import CustomCard from '../common/Card';
import DeepBlueButton from '../common/DeepBlueButton';
import { HeadingPage } from '../common/HeadingPage';
import YellowButton from '../common/YellowButton';
import { MdAdd } from 'react-icons/md';
import { ReportSVG } from '../common/sidebarSVG/report';

export const ReportTypeList = () => {
  const [isLoading, setLoading] = useState(false);
  const [types, setTypes] = useState<any[]>([]);
  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [edittingReportType, setEdittingReportType] = useState<any>(null);
  const [deletingReportType, setDeletingReportType] = useState<any>(null);

  const getReportTypeList = useCallback(async () => {
    setLoading(true);
    const data = await getReportTypes();

    setTypes(data);

    setLoading(false);
  }, []);

  useEffect(() => {
    getReportTypeList();
  }, [getReportTypeList]);

  return (
    <Box>
      <Container maxW='container.xl' paddingLeft="0px" paddingRight="0px">
        <Flex align='end' justify='start'>
          <ReportSVG
            color='#2E5FA3'
            size='26'
            style={{ marginRight: 8 }}
          />
          <HeadingPage>行政系 レポートの種類</HeadingPage>
        </Flex>
      </Container>
      <CustomCard marginTop='24px'>
        <HStack marginBottom={5} justifyContent='end'>
          <YellowButton onClick={() => setAddModalOpen(true)}>
            <Flex width='100%' height='100%' align='center' justify={'center'}>
              新規作成
              <MdAdd
                size={24}
                style={{
                  marginLeft: 8,
                }}
              />
            </Flex>
          </YellowButton>
        </HStack>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            marginTop: 32,
          }}
        >
          {types.map((type: any, index) => {
            return (
              <ReportTypeItem
                key={index}
                name={type.name}
                description={type.description}
                id={type.id}
                onEdit={() => {
                  setEdittingReportType(type);
                }}
                onDelete={() => {
                  setDeletingReportType(type);
                }}
              />
            );
          })}
          {isLoading && (
            <Flex mt={6} mb={6} justify='center'>
              <Spinner />
            </Flex>
          )}
        </div>
        <AddReportTypeModal
          isVisible={isAddModalOpen}
          setVisible={async (isVisible, isReloadData) => {
            setAddModalOpen(isVisible);

            if (isReloadData) {
              setTypes([]);
              await getReportTypeList();
            }
          }}
        />
        <EditReportTypeModal
          isVisible={!!edittingReportType}
          initData={edittingReportType}
          setVisible={async (isReloadData) => {
            setEdittingReportType(null);

            if (isReloadData) {
              setTypes([]);
              await getReportTypeList();
            }
          }}
        />
        <DeleteReportTypeModal
          isVisible={!!deletingReportType}
          id={deletingReportType?.id}
          setVisible={async (isReloadData) => {
            setDeletingReportType(null);

            if (isReloadData) {
              setTypes([]);
              await getReportTypeList();
            }
          }}
        />
      </CustomCard>
    </Box>
  );
};
