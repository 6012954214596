import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
  Input,
  Text,
  Select,
} from '@chakra-ui/react';
import { updateMember } from '../../../../services/apiRequests/members';

import { styles } from './styles';
import { IMember } from '../../../../models/member';
import DeepBlueButton from '../../../common/DeepBlueButton';
import { CODE, ERROR_CODE } from '../../../../utils/constants';

type Props = {
  isVisible: boolean;
  setVisible: (isVisible: boolean, reloadData: boolean) => void;
  initialData: IMember;
  memberId: number;
};

interface Temp {
  username?: string;
  password?: string;
  code?: string;
}

export const EditMemberModal: FC<Props> = ({
  isVisible,
  setVisible,
  initialData,
  memberId,
}) => {
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>(initialData.username);
  const [password, setPassword] = useState<string>(initialData.password);
  const [email, setEmail] = useState<string>(initialData.email || '');
  const [firstName, setFirstName] = useState<string>(
    initialData.last_name || ''
  );
  const [lastName, setLastName] = useState<string>(
    initialData.first_name || ''
  );
  const [type, setType] = useState<String>(
    initialData.type === 'Director' ? 'D' : 'E'
  );
  const [errors, setErrors] = useState<Temp>({});
  const [code, setCode] = useState<string>('');

  const reset = useCallback(() => {
    setUsername(initialData.username);
    setEmail(initialData.email || '');
    setFirstName(initialData.first_name || '');
    setLastName(initialData.last_name || '');
    setType(initialData.type === 'Director' ? 'D' : 'E');
    setPassword(initialData.password);
    setErrors({});
    setCode(initialData?.code || '');
  }, [initialData]);

  useEffect(() => {
    reset();
  }, [isVisible]);

  const handleEditMember = useCallback(async () => {
    try {
      const tempErrors: Temp = {};
      if (!username) {
        tempErrors.username = ' ユーザー名を入力してください。';
      }

      if (!password) {
        tempErrors.password = 'パスワードを入力してください。';
      } else if (password.length < 8) {
        tempErrors.password =
          'パスワードは文字数が8文字以上で、文字、数字と特殊文字を含める必要があります。';
      }

      if (!code) {
        tempErrors.code = ERROR_CODE;
      }

      setErrors(tempErrors);
      const keysErrors = Object.keys(tempErrors);
      if (keysErrors.length > 0) {
        return;
      }
      setLoading(true);
      const { isOk, errors: errorResponse } = await updateMember({
        id: memberId,
        username,
        password,
        email,
        firstName,
        lastName,
        type,
        code,
      });

      if (isOk) {
        toast({
          title: '運転手の更新に成功しました。',
          description: '',
          status: 'success',
          duration: 2000,
        });

        setVisible(false, true);
      } else {
        if (!isOk && errorResponse && errorResponse?.code) {
          setErrors((curr) => {
            return {
              ...curr,
              code: errorResponse?.code,
            };
          });
        }
        toast({
          title: '運転手の更新に失敗しました。',
          description: '',
          status: 'error',
          duration: 2000,
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [username, password, email, firstName, lastName, type, memberId, code]);

  return (
    <Modal
      size='xl'
      isOpen={isVisible}
      onClose={() => {
        if (isLoading) {
          return;
        }
        setVisible(false, false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>運転手の情報を更新します。</ModalHeader>
        <ModalCloseButton disabled={isLoading} />
        <ModalBody>
          <div className={styles.container}>
            <div className={styles.infoContainer}>
              <Text fontWeight='bold' mb={2}>
                ユーザー名
              </Text>
              <Input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder='ユーザー名'
                maxLength={100}
                mb={errors?.username ? 1 : 4}
                isInvalid={!!errors?.username}
              />
              {errors.username ? (
                <Text color='red'>{errors.username}</Text>
              ) : null}

              <Text fontWeight='bold' mb={2}>
                パスワード
              </Text>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='*******'
                maxLength={100}
                mb={errors?.password ? 1 : 4}
                isInvalid={!!errors?.password}
              />
              {errors.password ? (
                <Text color='red'>{errors.password}</Text>
              ) : null}

              <Text fontWeight='bold' mb={2}>
                姓
              </Text>
              <Input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder='姓'
                maxLength={100}
                mb={4}
              />

              <Text fontWeight='bold' mb={2}>
                名
              </Text>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder='名'
                maxLength={100}
                mb={4}
              />
              <Text fontWeight='bold' mb={2}>
                メールアドレス
              </Text>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='abc@mail.com'
                maxLength={100}
                mb={4}
              />
              <Text fontWeight='bold' mb={2}>
                アカウント種類
              </Text>
              <Select
                mb={4}
                value={type as string}
                onChange={(e) => setType(e.target.value)}
              >
                <option value='E'>運転手</option>
                <option value='D'>管理者</option>
              </Select>

              <Text fontWeight='bold' mb={2}>
                {CODE}
              </Text>
              <Input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder={CODE}
                maxLength={30}
                mb={errors?.code ? 1 : 4}
                isInvalid={!!errors?.code}
              />
              {errors.code ? <Text color='red'>{errors.code}</Text> : null}
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <DeepBlueButton isLoading={isLoading} onClick={handleEditMember}>
            更新する
          </DeepBlueButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
