import React from 'react';
import {
  Button,
  InputGroup,
  FormLabel,
  FormControl,
  Input,
  Select,
  FormErrorMessage,
  Box,
  Flex,
  FormLabelProps,
  InputProps,
} from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { Field } from 'formik';
import DeepBlueButton from '../../common/DeepBlueButton';
import { ICustomer } from '../../../models/customer';
import MultiSelect from '../../common/MultiSelect';

const labelStyle: FormLabelProps = {
  color: '#6F85A3',
  fontSize: '14px',
  fontWeight: 'bold',
};

const inputStyles: InputProps = {
  minHeight: '40px',
  border: '1px solid #E4E4E4',
  borderRadius: '5px',
};

export const CreateModalStep2 = (props) => {
  const {
    setFieldValue,
    setStep,
    values,
    handleChange,
    handleBlur,
    errors,
    customerList,
    selectedGarbages,
    touched,
    setSelectedGarbages,
    garbageList,
  } = props;
  const customerOptions = customerList.map((customer: ICustomer) => ({
    value: customer.id,
    label: customer.description || customer.name,
  }));

  const handleSetGarbageType = (e) => {
    if (e && Array.isArray(e) && e.length <= 8) {
      setSelectedGarbages(e);
    };
  };
  const handleSetCustomer = (e) => {
    values.customer = e.value;
  };

  const handleDisplayBaseType = () => {
    return (
      <>
        <FormControl isInvalid={!!(errors.name && touched.name)}>
          <FormLabel htmlFor='name' {...labelStyle}>
            ルート名
          </FormLabel>
          <InputGroup>
            <Input
              type='name'
              name='name'
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='ルート名を入力'
              size='md'
              {...inputStyles}
            />
          </InputGroup>
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>
        <InputGroup>
          <FormControl id='customer'>
            <Field name='customer'>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.customer && form.touched.customer}
                >
                  <FormLabel htmlFor='customer' {...labelStyle}>
                    顧客
                  </FormLabel>

                  <ReactSelect
                    placeholder='顧客を選択'
                    name='customer'
                    options={customerOptions}
                    isSearchable
                    isClearable
                    onChange={handleSetCustomer}
                  >
                  </ReactSelect>
                  <FormErrorMessage>{form.errors.customer}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </FormControl>
        </InputGroup>
        <InputGroup>
          <FormControl id='garbage'>
            <Field name='garbage'>
              {({ field, form }: { field: any; form: any }) => {
                return (
                  <FormControl
                    isInvalid={form.errors.garbage}
                  >
                    <FormLabel htmlFor='garbage' {...labelStyle}>
                      品目
                    </FormLabel>
                    <MultiSelect
                      {...field}
                      isMulti
                      name='garbage'
                      onChange={handleSetGarbageType}
                      options={garbageList}
                      placeholder='品目を選択'
                      closeMenuOnSelect={false}
                      size='md'
                      value={selectedGarbages}
                    />
                    <FormErrorMessage>{form.errors.garbage}</FormErrorMessage>
                  </FormControl>
                );
              }}
            </Field>
          </FormControl>
        </InputGroup>
      </>
    );
  };

  const handleDisplayCompanyType = () => {
    return (
      <>
        <FormControl isInvalid={!!(errors.name && touched.name)}>
          <FormLabel htmlFor='name' {...labelStyle}>
            ルート名
          </FormLabel>
          <InputGroup>
            <Input
              type='name'
              name='name'
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder='ルート名を入力'
              size='md'
              {...inputStyles}
            />
          </InputGroup>
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>
      </>
    );
  };

  return (
    <>
      {values.type === 'BASE'
        ? handleDisplayBaseType()
        : handleDisplayCompanyType()}
    </>
  );
};
