import React, { useState } from 'react';
import { Button, Box, Flex } from '@chakra-ui/react';
import DeepBlueButton from '../../common/DeepBlueButton';
import YellowButton from '../../common/YellowButton';

const TYPE = {
  COMPANY: 'COMPANY',
  BASE: 'BASE',
};

export const CreateModalStep1 = (props) => {
  const { setFieldValue, setStep } = props;

  const [currentStep, setCurrentType] = useState(TYPE.BASE);

  const handleClickNext = () => {
    if (currentStep) {
      setFieldValue('type', currentStep);
      setStep((step) => {
        return step + 1;
      });
    }
  };

  const handleSelectType = (type) => {
    setCurrentType(type);
  };

  return (
    <Box>
      <Flex align='center' justify='space-between' padding='24px'>
        <DeepBlueButton
          size='lg'
          width='150px'
          height='120px'
          color='#ffff'
          boxShadow='none !important'
          {...(currentStep === TYPE.BASE
            ? {
              backgroundColor: 'type.governmentSelected'
            }
            : {
              backgroundColor: 'type.government'
            })}
          _hover=''
          onClick={() => {
            handleSelectType(TYPE.BASE);
          }}
          fontSize='14px'
        >
          行政系廃棄物収集
        </DeepBlueButton>
        <DeepBlueButton
          size='lg'
          height='120px'
          width='150px'
          fontSize='14px'
          onClick={() => {
            handleSelectType(TYPE.COMPANY);
          }}
          _hover=''
          color='#ffff'
          boxShadow='none !important'
          {...(currentStep === TYPE.COMPANY
            ? {
              backgroundColor: 'type.companySelected',
            }
            : {
              backgroundColor: 'type.company',
            })}
        >
          事業系廃棄物収集
        </DeepBlueButton>
      </Flex>
      <Flex justify='center' align='center' padding='18px'>
        <YellowButton onClick={handleClickNext}>次へ</YellowButton>
      </Flex>
    </Box>
  );
};
