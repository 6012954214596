import React from 'react';
import { Heading } from '@chakra-ui/react';

export const HeadingPage = ({ style, children }) => {
  return (
    <Heading
      color='#0A1524'
      fontSize='22'
      fontWeight='600'
      textAlign='start'
      style={style}
    >
      {children}
    </Heading>
  );
};
