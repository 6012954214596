import React, { useState, useCallback, useEffect } from 'react';
import {
  Container,
  Heading,
  HStack,
  Spinner,
  Flex,
  Button,
  Box,
} from '@chakra-ui/react';

import { getGarbages } from '../../services/apiRequests/garbages';

import { GarbageTypeItem } from './components/GarbageTypeItem';
import { AddGarbageTypeModal } from './components/AddGarbageTypeModal';
import { DeleteGarbageTypeModal } from './components/DeleteGarbageTypeModal';
import { EditGarbageTypeModal } from './components/EditGarbageTypeModal';
import CustomCard from '../common/Card';
import DeepBlueButton from '../common/DeepBlueButton';
import { HeadingPage } from '../common/HeadingPage';
import YellowButton from '../common/YellowButton';
import { MdAdd } from 'react-icons/md';
import { TrashSVG } from '../common/sidebarSVG/trash';

export const GarbageTypeList = () => {
  const [isLoading, setLoading] = useState(false);
  const [types, setTypes] = useState<any[]>([]);
  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [deletingGarbageType, setDeletingGarbageType] = useState<any>(null);
  const [edittingGarbageType, setEdittingGarbageType] = useState<any>(null);

  const handleGetGarbages = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getGarbages();

      setTypes(data);

      setLoading(false);
    } catch (error) { }
  }, []);

  useEffect(() => {
    handleGetGarbages();
  }, [handleGetGarbages]);

  return (
    <Box>
      <Container maxW='container.xl' paddingLeft="0px" paddingRight="0px">
        <Flex align='end' justify='start'>
          <TrashSVG
            color='#2E5FA3'
            size='26'
            style={{ marginRight: 8 }}
          />
          <HeadingPage>行政系 ゴミの種類</HeadingPage>
        </Flex>
      </Container>
      <CustomCard marginTop='24px'>
        <Container maxW='container.xl'>
          <HStack marginBottom={5} justifyContent='end'>
            <YellowButton onClick={() => setAddModalOpen(true)}>
              <Flex width='100%' height='100%' align='center' justify={'center'}>
                新規作成
                <MdAdd
                  size={24}
                  style={{
                    marginLeft: 8,
                  }}
                />
              </Flex>
            </YellowButton>
          </HStack>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              marginTop: 32,
            }}
          >
            {types.map((type: any, index) => {
              return (
                <GarbageTypeItem
                  key={index}
                  name={type.name}
                  description={type.description}
                  id={type.id}
                  onEdit={() => {
                    setEdittingGarbageType(type);
                  }}
                  onDelete={() => {
                    setDeletingGarbageType(type);
                  }}
                />
              );
            })}
            {isLoading && (
              <Flex mt={6} mb={6} justify='center'>
                <Spinner />
              </Flex>
            )}
          </div>
          <AddGarbageTypeModal
            isVisible={isAddModalOpen}
            setVisible={async (isVisible, isReloadData) => {
              setAddModalOpen(isVisible);

              if (isReloadData) {
                setTypes([]);
                await handleGetGarbages();
              }
            }}
          />
          <DeleteGarbageTypeModal
            isVisible={!!deletingGarbageType}
            id={deletingGarbageType?.id}
            setVisible={async (isReloadData) => {
              setDeletingGarbageType(null);

              if (isReloadData) {
                setTypes([]);
                await handleGetGarbages();
              }
            }}
          />
          <EditGarbageTypeModal
            isVisible={!!edittingGarbageType}
            initData={edittingGarbageType}
            setVisible={async (isReloadData) => {
              setEdittingGarbageType(null);

              if (isReloadData) {
                setTypes([]);
                await handleGetGarbages();
              }
            }}
          />
        </Container>
      </CustomCard>
    </Box>
  );
};
