import AsyncSelect from 'react-select/async';
import React, { useState } from 'react';
import './style.css';
import { baseStyle, useToast } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import { getCompanyCustomer } from '../../services/apiRequests/garbageCompany';
export const Selector = ({ selectedCustomer, setSelectedCustomer, errors }) => {
  const [listOptions, setListOptions] = useState([]);

  const toast = useToast();

  async function _getCustomers(callback) {
    try {
      let customers = await getCompanyCustomer();
      customers = customers.map((c) => {
        return {
          ...c,
          value: c?.id,
          label: c?.name,
        };
      });
      setListOptions(customers);
      setSelectedCustomer([]);
      if (callback) {
        callback(customers);
      }
    } catch (e) {
      toast({
        title: '顧客の取得にエラーが発生しました',
        description: 'もう一度お試しください',
        status: 'error',
      });
    }
  }

  const handleSearchUser = (inputValue, callback) => {
    if (listOptions && listOptions.length === 0) {
      _getCustomers(callback);
    } else if (listOptions && listOptions.length > 0) {
      const filterOptions = listOptions.filter((option) => {
        return (
          `${option?.name}`
            .toLowerCase()
            .indexOf(`${inputValue}`.toLowerCase()) !== -1
        );
      });
      callback(filterOptions);
    }
  };

  const handleSelectCustomer = (e) => {
    setSelectedCustomer(e);
  };

  return (
    <Flex width={'100%'}>
      <AsyncSelect
        isMulti
        isSearchable={true}
        name='garbage'
        onChange={handleSelectCustomer}
        placeholder='顧客を選択'
        // closeMenuOnSelect={false}
        className='user-selector'
        styles={{
          container: (baseStyles, state) => ({
            ...baseStyles,
            width: '100%',
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: '100%',
            border: `1px solid ${errors ? 'red' : '#C6D5EB'}`,
            minHeight: '2.5rem',
            borderRadius: '4px 4px 4px 4px',
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            height: '100%',
            width: '100%',
            fontSize: '16px',
            textAlign: 'left',
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            textAlign: 'left',
            fontSize: 16,
          }),
          multiValue: (baseStyle) => ({
            ...baseStyle,
            backgroundColor: '#edf2f7',
          }),
          multiValueRemove: (baseStyle) => ({
            ...baseStyle,
            backgroundColor: 'unset !important',
            color: 'unset !important',
            '&:hover': {
              opacity: '0.8',
            },
          }),
        }}
        loadOptions={handleSearchUser}
        cacheOptions={false}
        defaultOptions
        value={selectedCustomer}
        noOptionsMessage={() => {
          return <div>他の選択肢がございません。</div>;
        }}
      />
    </Flex>
  );
};
