import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  InputLeftElement,
  Button,
  InputGroup,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormLabel,
  FormControl,
  Input,
  ModalFooter,
  Select,
  FormErrorMessage,
  useToast,
  chakra,
  FormLabelProps,
  InputProps,
} from '@chakra-ui/react';
import { Formik, Field } from 'formik';
import { RiRouteFill } from 'react-icons/ri';
import { ICustomer } from '../../../models/customer';
import { getCustomers } from '../../../services/apiRequests/customers';
import {
  editBaseRoute,
  saveBaseRoute,
} from '../../../services/apiRequests/baseRoute';
import MultiSelect from '../../common/MultiSelect';
import { getGarbages } from '../../../services/apiRequests/garbages';
import { IGarbage } from '../../../models/garbage';
import { handleFetchBaseRoute } from '../../../store/thunks/BaseRoute';
import { IBaseRoute } from '../../../models/baseRoute';
import {
  getCompanyCustomer,
  getCompanyGarbage,
} from '../../../services/apiRequests/garbageCompany';
import CancelModalButton from '../../common/CancelModalButton';
import YellowButton from '../../common/YellowButton';
import ReactSelect from 'react-select';

interface CreateBaseRouteModalProps {
  isOpen: boolean;
  onClose: () => void;
  baseRoute: IBaseRoute | null;
}

interface IGarbageOptions {
  garbage: IGarbage;
  value: number;
  label: string;
}

const roueTypeArray = {
  COMPANY: 'COMPANY',
  BASE: 'BASe',
};

const routeType = [
  {
    label: 'Base',
    value: 'BASE',
  },
  {
    label: 'Company',
    value: 'COMPANY',
  },
];

export const CreateBaseRouteModal = (props: CreateBaseRouteModalProps) => {
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [garbages, setGarbages] = useState<IGarbageOptions[]>([]);
  const [selectedGarbages, setSelectedGarbages] = useState<number[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any | null>(null);

  const toast = useToast();

  const { isOpen, onClose, baseRoute, onSuccess } = props;

  //edit current base route
  useEffect(() => {
    if (!baseRoute) return;
    if (baseRoute.garbage && Array.isArray(baseRoute.garbage)) {
      const parsedGarbage = baseRoute.garbage.map((gb) => {
        return {
          ...gb,
          value: gb.id,
          label: gb.name,
        };
      });
      setSelectedGarbages(parsedGarbage);
    }
    if (baseRoute.customer) {
      setSelectedCustomer({
        value: baseRoute.customer.id,
        label: baseRoute.customer.name,
      });
    }
  }, [baseRoute]);

  useEffect(() => {
    async function _getCustomers() {
      try {
        const customers = await getCustomers();
        setCustomers(customers);
      } catch (e) {
        toast({
          title: '顧客の取得にエラーが発生しました',
          description: 'もう一度お試しください',
          status: 'error',
        });
      }
    }

    async function _getGarbages() {
      try {
        const _garbages = await getGarbages();
        const options: IGarbageOptions[] = [];
        for (const gb of _garbages) {
          options.push({
            ...gb,
            value: gb.id,
            label: gb.name,
          });
        }
        setGarbages(options);
      } catch (e) {
        toast({
          title: 'ごみ収集にエラーが発生しました',
          description: 'もう一度お試しください',
          status: 'error',
        });
      }
    }

    _getCustomers();
    _getGarbages();
  }, [toast]);

  const save = async (data: any) => {
    if (baseRoute) {
      // editing
      await editBaseRoute(baseRoute?.id, data);
      toast({
        title: '編集',
        description: '',
        status: 'success',
      });
    } else {
      // create new
      await saveBaseRoute(data);
      toast({
        title: '新規作成',
        description: '',
        status: 'success',
      });
    }
  };

  const title = `${baseRoute ? '編集' : '収集ポイント'}`;
  const buttonText = `${baseRoute ? '更新する' : '追加する'}`;

  const labelStyle: FormLabelProps = {
    color: '#6F85A3',
    fontSize: '14px',
    fontWeight: 'bold',
  };

  const inputStyles: InputProps = {
    minHeight: '40px',
    border: '1px solid #E4E4E4',
    borderRadius: '5px',
  };

  const handleOnClose = () => {
    setSelectedGarbages([]);
    onClose();
  };

  const handleSetGarbageType = (e) => {
    if (e && Array.isArray(e) && e.length <= 8) {
      setSelectedGarbages(e);
    };
  };

  const customerOptions = customers.map((customer) => ({
    value: customer.id,
    label: customer.name,
  }));

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display='flex'
          alignItems='center'
          padding='12px 0 12px 24px'
          justifyContent='space-between'
          borderBottom='1px solid #C6D5EB'
        >
          <chakra.span fontSize='16px'>{title}</chakra.span>
          <CancelModalButton
          />
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            name: baseRoute?.name ?? '',
            customer: baseRoute?.customer?.id ?? '',
          }}
          validate={(values) => {
            const errors = {};
            if (values.name.trim().length <= 0) {
              errors.name = 'ルート名を入力してください';
            }
            if (baseRoute?.type !== 'COMPANY' && selectedGarbages.length === 0) {
              errors.garbage = '少なくとも 1 つのアイテムを選択してください。'
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const _name = values.name.trim();
              await save({
                name: _name,
                customer: values.customer,
                garbage: selectedGarbages.map((gb) => gb.value),
                type: values.type,
              });
              await handleFetchBaseRoute();
              setSelectedGarbages([]);
              if (onSuccess) {
                await onSuccess();
              }
              onClose();
            } catch (e) {
              toast({
                title: 'エラー',
                description: 'ログイン情報をご確認ください。',
                status: 'error',
              });
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            const handleSetCustomer = (e) => {
              setSelectedCustomer(e);
              values.customer = e ? e.value : '';
            };
            return (
              <form onSubmit={handleSubmit}>
                <ModalBody
                  display='flex'
                  flexDirection='column'
                  style={{ gap: '24px' }}
                  padding='32px 32px 0 32px'
                >
                  <FormControl isInvalid={!!(errors.name && touched.name)}>
                    <FormLabel htmlFor='name' {...labelStyle}>
                      ルート名
                    </FormLabel>
                    <InputGroup>
                      <Input
                        type='name'
                        name='name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='ルート名を入力'
                        size='md'
                        {...inputStyles}
                      />
                    </InputGroup>
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <InputGroup
                    display={baseRoute?.type === 'COMPANY' ? 'none' : 'block'}
                  >
                    <FormControl id='customer'>
                      <Field name='customer'>
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            isInvalid={
                              form.errors.customer && form.touched.customer
                            }
                          >
                            <FormLabel htmlFor='customer' {...labelStyle}>
                              顧客
                            </FormLabel>

                            <ReactSelect
                              placeholder='顧客を選択'
                              name='customer'
                              options={customerOptions}
                              isSearchable
                              isClearable
                              onChange={handleSetCustomer}
                              value={selectedCustomer}
                            >
                            </ReactSelect>
                            <FormErrorMessage>
                              {form.errors.customer}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </FormControl>
                  </InputGroup>
                  <InputGroup
                    display={baseRoute?.type === 'COMPANY' ? 'none' : 'block'}
                  >
                    <FormControl id='garbage'>
                      <Field name='garbage'>
                        {({ field, form }: { field: any; form: any }) => {
                          return (
                            <FormControl
                              isInvalid={
                                form.errors.garbage
                              }
                            >
                              <FormLabel htmlFor='garbage' {...labelStyle}>
                                品目
                              </FormLabel>
                              <MultiSelect
                                {...field}
                                isMulti
                                name='garbage'
                                onChange={handleSetGarbageType}
                                options={garbages}
                                placeholder='品目を選択'
                                closeMenuOnSelect={false}
                                size='md'
                                value={selectedGarbages}
                              />
                              <FormErrorMessage>
                                {form.errors.garbage}
                              </FormErrorMessage>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </FormControl>
                  </InputGroup>
                </ModalBody>
                <ModalFooter
                  display='flex'
                  justifyContent='flex-start'
                  marginTop='24px'
                  paddingTop='0px'
                  paddingBottom='40px'
                  paddingX='32px'
                >
                  <Button
                    disabled={isSubmitting}
                    onClick={handleOnClose}
                    mr={3}
                    height='48px'
                    borderRadius='36px'
                    border='1px solid #C6D5EB'
                    padding='0 27px'
                    fontSize='16px'
                    color='#6F85A3'
                    variant='outline'
                  >
                    キャンセル
                  </Button>
                  <YellowButton
                    disabled={isSubmitting}
                    type='submit'
                    height='48px'
                    fontSize='16px'
                    fontWeight='400'
                    display="block"
                  >
                    {/* 保存 */}
                    {buttonText}
                  </YellowButton>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
