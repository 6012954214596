import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Input,
  Box
} from '@chakra-ui/react';
import { RedButton } from '../../common/RedButton';

export const DeleteCustomerModal = ({
  isOpen,
  onClose,
  onSubmit,
  companyDetails
}: {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: Function,
  companyDetails?: any
}) => {

  const handleSubmit = async () => {
    const { id } = companyDetails;
    await onSubmit(id, () => {
      onClose();
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='sm'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>顧客を削除</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign='center'>
          本当に削除しますか？
        </ModalBody>
        <ModalFooter alignItems='center' justifyContent={'center'} flexDirection='column'>
          <RedButton
            onClick={handleSubmit}
          >
            削除
          </RedButton>
          <Button
            variant='outline'
            onClick={onClose}
            style={{
              borderRadius: '36px',
              boxShadow: 'none'
            }}
            borderColor="sidebar.selectedTextColor"
            color="table.headerColor"
          >
            キャンセル
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}