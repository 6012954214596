export function isNumeric(str: any) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

const MAX_LENGTH = 3;

export function formatCollectionPointSequence(value: string | number) {
  let numberCP = '';
  if (typeof value === 'string' && isNumeric(value)) {
    numberCP = value;
  } else if (typeof value === 'number') {
    numberCP = value.toString();
  }
  if (numberCP.length > 0) {
    return prefixCollectionPointSequence(numberCP);
  }
  return value;
}

function prefixCollectionPointSequence(value: string) {
  const currentValueLength = value.length;
  if (currentValueLength > MAX_LENGTH) {
    return value;
  }
  const lengthPrefix = MAX_LENGTH - currentValueLength;
  let prefix = '';
  for (let i = 0; i < lengthPrefix; i++) {
    prefix += '0';
  }
  return `${prefix}${value}`;
};
