import React, { useEffect, useRef, useState } from 'react';
import { Container, Button, Box, Flex, Text, VStack } from '@chakra-ui/react';

import DatePicker from '../DatePicker';

import { CSVLink } from 'react-csv';
import moment from 'moment';

import { hirouAxios } from '../../services/httpInstance';
import { ROUTE_EXPORT_CSV } from '../../constants/urls';
import { ReportSVG } from '../common/sidebarSVG/report';
import { HeadingPage } from '../common/HeadingPage';
import CustomCard from '../common/Card';
import { Selector } from './Selector';
import './style.css';
import { CustomSpinner } from '../common/Spinner';

interface IErrors {
  customer?: string;
}

export const ExportCsv = () => {
  const [start_date, setStartDate] = useState<Date>(new Date());
  const [end_date, setEndDate] = useState<Date>(new Date());
  const [data, setData] = useState<any>(null);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [errors, setErrors] = useState<IErrors>({});
  const [exporting, setExporting] = useState(false);
  const [isDisplayCustomer, setIsDisplayCustomer] = useState(false);
  const linkRef = useRef(null);

  const url = ROUTE_EXPORT_CSV;
  const dataExport = async (e) => {
    setErrors({});
    const formData = new FormData();
    formData.append('start_date', moment(start_date).format('YYYY-MM-DD'));
    formData.append('end_date', moment(end_date).format('YYYY-MM-DD'));
    if (selectedCustomer && selectedCustomer.length > 0) {
      formData.append(
        'customer_id',
        selectedCustomer.map((selected) => selected?.value).join(',')
      );
    }
    const response = await hirouAxios.post(url, formData);
    setData(response.data?.data);
    setExporting(true);
  };
  // useEffect(() => {
  //   dataExport();
  // }, []);
  // @ts-ignore
  useEffect(() => {
    if (exporting && (data !== null || data !== undefined) && linkRef) {
      const linkDOM = document.querySelector('#export-link');
      if (linkDOM) {
        linkDOM.click();
        setExporting(false);
        setData(null);
      }
    }
  }, [data, exporting]);

  return (
    <>
      <Container
        maxW='container.xl'
        paddingLeft='0px'
        paddingRight='0px'
        marginBottom={5}
      >
        <Flex align='center' justify='start'>
          <ReportSVG color='#2E5FA3' size='26' style={{ marginRight: 8 }} />
          <HeadingPage>月次集計</HeadingPage>
        </Flex>
      </Container>
      <Container maxW='container.xl' padding='0px 0px'>
        {exporting && <CustomSpinner />}
        <CustomCard>
          <VStack align={'start'}>
            <Flex>
              <Box id='start-date-export'>
                <Text
                  textAlign={'left'}
                  fontSize='14px'
                  fontWeight={'600'}
                  marginBottom='4px'
                >
                  開始日
                </Text>
                <DatePicker
                  selectedDate={start_date}
                  onChange={(e: any) => setStartDate(e)}
                  containerStyle={{
                    width: '450px',
                    boxSizing: 'border-box',
                    marginRight: '15px',
                  }}
                  styles={{
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: 8,
                    }),
                  }}
                />
              </Box>
              <Box id='end-date-export'>
                <Text
                  textAlign={'left'}
                  fontSize='14px'
                  fontWeight={'600'}
                  marginBottom='4px'
                >
                  終了日
                </Text>
                <DatePicker
                  selectedDate={end_date}
                  onChange={(e: any) => setEndDate(e)}
                  containerStyle={{
                    width: '450px',
                    marginRight: '15px',
                  }}
                />
              </Box>
            </Flex>
            <Flex
              align='center'
              marginTop='12px !important'
              // marginBottom='12px !important'
            >
              <Button
                variant='outline'
                onClick={() => {
                  setIsDisplayCustomer((current) => {
                    return !current;
                  });
                  setSelectedCustomer([]);
                }}
                style={{
                  boxShadow: 'none',
                }}
              >
                顧客を選択
              </Button>
              <Text fontSize='14px' marginBottom='4px' marginLeft='10px'>
                ※選択しない場合は全ての顧客データを出力します。
              </Text>
            </Flex>
            {isDisplayCustomer && (
              <Box width='450px'>
                <Selector
                  setSelectedCustomer={setSelectedCustomer}
                  selectedCustomer={selectedCustomer}
                  errors={errors?.customer}
                />
                {errors?.customer && (
                  <Text textAlign={'left'} color={'red'} fontSize='14px'>
                    {errors?.customer}
                  </Text>
                )}
              </Box>
            )}
            <Box>
              <Button onClick={dataExport}>CSVファイルダウンロード</Button>
              <CSVLink
                data={data || ''}
                filename={'export.csv'}
                className='chakra-button'
                style={{
                  visibility: 'hidden',
                }}
                id='export-link'
                ref={linkRef}
                enclosingCharacter={''}
                separator=','
              >
                CSVファイルダウンロード
              </CSVLink>
            </Box>
          </VStack>
        </CustomCard>
      </Container>
    </>
  );
};
