import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

export class InfoWindow extends React.Component {

	componentDidMount() {
		this.renderInfoWindow();
	}

	componentWillUnmount(): void {
		if (this.containerDOM) {
			const { onClick } = this.props;
			this.containerDOM.removeEventListener('click', onClick);
		}
	}

	componentDidUpdate(prevProps) {
		const { google, map } = this.props;
		if (!google || !map) {
			return;
		}

		if (this.props.ariaLabel !== prevProps.ariaLabel) {
			this.updateArial();
		}

		if (map !== prevProps.map) {
			this.renderInfoWindow();
		}

		if (this.props.position !== prevProps.position) {
			this.updatePosition();
		}

		if (this.props.children !== prevProps.children) {
			this.updateContent();
		}
		// console.log('==================================================')
		// console.log("prevProps", prevProps)
		// console.log('map', map !== prevProps.map)
		// console.log('position', this.props.position !== prevProps.position)
		// console.log('children', this.props.children !== prevProps.children)
		// console.log('visible', this.props.visible !== prevProps.visible)
		// console.log('marker', this.props.marker !== prevProps.marker)
		// console.log('position', this.props.position !== prevProps.position)
		// console.log('visible', this.props.visible)

		if ((this.props.visible !== prevProps.visible ||
			this.props.marker !== prevProps.marker ||
			this.props.position !== prevProps.position)) {
			this.props.visible
				? this.openWindow()
				: this.closeWindow();
		}
	}

	renderInfoWindow() {
		const {
			map,
			google,
			mapCenter,
			...props
		} = this.props;

		if (!google || !google.maps) {
			return;
		}

		const iw = this.infowindow = new google.maps.InfoWindow({
			content: '',
			disableAutoPan: true,
			...props
		});

		google.maps.event
			.addListener(iw, 'closeclick', this.onClose.bind(this));
		google.maps.event
			.addListener(iw, 'domready', this.onOpen.bind(this));
	}

	onOpen() {
		if (this.props.onOpen) {
			this.props.onOpen();
		}
	}

	onClose() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	openWindow() {
		this.infowindow.open({
			map: this.props.map,
		});
	}

	updatePosition() {
		let pos = this.props.position;
		if (!(pos instanceof google.maps.LatLng)) {
			pos = pos && new google.maps.LatLng(pos.lat, pos.lng);
		}
		this.infowindow.setPosition(pos);
	}

	updateContent() {
		const { onClick } = this.props;
		const content = this.renderChildren();
		const containerDOM = document.createElement('div');
		containerDOM.innerHTML = content;
		if (onClick) {
			containerDOM.addEventListener('click', () => {
				onClick();
			});
		}
		this.containerDOM = containerDOM;
		this.infowindow.setContent(containerDOM);
	}

	updateArial() {
		this.infowindow.setOptions({
			ariaLabel: this.props.ariaLabel,
		});
	}

	closeWindow() {
		this.infowindow.close();
	}

	renderChildren() {
		const { children } = this.props;

		return ReactDOMServer.renderToString(children);
	}

	render() {
		return null;
	}
}

InfoWindow.propTypes = {
	children: PropTypes.element.isRequired,
	map: PropTypes.object,
	marker: PropTypes.object,
	position: PropTypes.object,
	visible: PropTypes.bool,

	// callbacks
	onClose: PropTypes.func,
	onOpen: PropTypes.func
};

InfoWindow.defaultProps = {
	visible: false
};

export default InfoWindow;
