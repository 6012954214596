import { Button } from '@chakra-ui/react';
import React from 'react';

export const RedButton = ({ children, ...rest }) => {
	return (
		<Button
			backgroundColor='sidebar.selectedLine'
			marginBottom="16px"
			style={{
				borderRadius: '36px',
			}}
			width='234px'
			_hover=""
			{...rest}
		>
			{children || '削除'}
		</Button>
	)
}
