import React, { useRef, useState, useEffect } from 'react';
import {
  Modal,
  Button,
  InputGroup,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormLabel,
  FormControl,
  Input,
  ModalFooter,
  FormErrorMessage,
  useToast,
  Image,
  Select,
  Box,
  styled,
} from '@chakra-ui/react';
import { Formik, Field } from 'formik';
import { handleFetchUpdatedBaseRoute } from '../../../store/thunks/BaseRoute';
import {
  editCollectionPoint,
  saveCollectionPoint,
  uploadImageCollectionPoint,
} from '../../../services/apiRequests/collectionPoint';
import { ICollectionPoint } from '../../../models/collectionPoint';
import {
  getCompanyCustomer,
  getCompanyGarbage, IGarbageCompany,
} from '../../../services/apiRequests/garbageCompany';
import MultiSelect from '../../common/MultiSelect';
import { AiOutlineCamera } from 'react-icons/ai';
import YellowButton from '../../common/YellowButton';
import Slider from 'react-slick';
import { MdCancel } from 'react-icons/md';
import style from './style.module.css';
import ReactSelect from 'react-select';

interface AddCollectionPointModalProps {
  isOpen: boolean;
  onClose: () => void;
  baseRouteId: number;
  marker: any;
  collectionPoint: ICollectionPoint | null;
  maxSequence: number;
  route: {
    [index: string]: any
  };
  setHighlightMark: (r: any) => void;
  isOnlyView?: boolean;
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  dotsClass: 'slideDotContainer slick-dots'
};

export const NewAddCollectionPointModal = (
  props: AddCollectionPointModalProps
) => {
  const toast = useToast();
  const { isOpen, onClose, baseRouteId, marker, collectionPoint, route, setHighlightMark, isOnlyView = false } =
    props;

  const [customerList, setCustomerList] = useState<any[]>([]);
  const [garbageList, setGarbageList] = useState<any[]>([]);
  const [selectedGarbage, setSelectedGarbage] = useState<any[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});
  const toastRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const slideRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!collectionPoint) return;
    if (collectionPoint?.garbage && Array.isArray(collectionPoint?.garbage)) {
      const parsedGarbage = collectionPoint.garbage.map((gb) => {
        return {
          ...gb,
          value: gb.id,
          label: gb.name,
        };
      });
      setSelectedGarbage(parsedGarbage);
      if (collectionPoint.customer) {
        setSelectedCustomer({
          value: collectionPoint.customer.id,
          label: collectionPoint.customer.description || collectionPoint.customer.name,
        });
      }
    }
  }, [collectionPoint]);

  useEffect(() => {
    async function _getCompanyCustomers() {
      try {
        const customers = await getCompanyCustomer();
        setCustomerList(customers);
      } catch (error) {
        toast({
          title: '顧客の取得にエラーが発生しました',
          description: 'もう一度お試しください',
          status: 'error',
        });
      }
    }

    async function _getCompanyGB() {
      try {
        const _garbages = await getCompanyGarbage();
        const options = [];
        for (const gb of _garbages) {
          options.push({
            ...gb,
            value: gb.id,
            label: gb.name,
          });
        }
        setGarbageList(options);
        // set select all gb when create new base route
        if (!collectionPoint) {
          setSelectedGarbage(options.slice(0, 8));
        }
      } catch (error) {
        toast({
          title: 'ごみ収集にエラーが発生しました',
          description: 'もう一度お試しください',
          status: 'error',
        });
      }
    }

    _getCompanyCustomers();
    _getCompanyGB();
  }, [toast]);

  const title = `${collectionPoint ? '編集' : '収集ポイント'}`;
  const buttonText = `${collectionPoint ? '更新する' : '追加する'}`;

  const handleSetGarbageType = (e: Array<any>) => {
    if (e && Array.isArray(e) && e.length <= 8) {
      setSelectedGarbage(e);
    };
  };

  const handleUploadImage = async (listImages: Array<any>, cpID: any) => {
    try {
      if (listImages.length > 0) {
        const promiseRequest = listImages.map((requestBodyItem) => {
          if (!requestBodyItem?.id) {
            const formData = new FormData();
            formData.append('image', requestBodyItem);
            return uploadImageCollectionPoint({
              cpID: cpID || collectionPoint!.id,
              data: formData
            });
          }
          return Promise.resolve(requestBodyItem);
        });
        const response = await Promise.allSettled(promiseRequest);
        return response;
      }
      return listImages;
    } catch (error) {
      toast({
        title: 'エラー',
        description: 'ログイン情報をご確認ください。',
        status: 'error',
      });
      console.log('error', error);
      return listImages;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottom='1px solid rgb(226, 232, 240)'
          position='relative'
        >{title}
          <ModalCloseButton
            top='50%'
            transform='translateY(-50%)'
            boxShadow='none !important'
          />
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            name: collectionPoint?.name ?? '',
            address: collectionPoint?.address ?? '',
            memo: collectionPoint?.memo ?? '',
            sequence: collectionPoint?.sequence ?? 0,
            image: collectionPoint?.images || [],
            customer: collectionPoint?.customer?.id || '',
          }}
          validate={(values) => {
            const errors: any = {};
            if (values.name.trim().length <= 0) {
              errors.name = '集積所名を入力してください';
            }
            if (route?.type === 'COMPANY' && selectedGarbage.length === 0) {
              errors.garbage = '少なくとも 1 つのアイテムを選択してください。';
            }
            if (route?.type === 'COMPANY' && !values.customer) {
              errors.customer = '顧客を選択してください';
            }
            return errors;
          }}
          onSubmit={async (values: any, { setSubmitting }) => {
            try {
              const _name = values.name.trim();
              const _address = values.address.trim();
              const _memo = values.memo.trim();

              const formData = new FormData();
              formData.append('name', _name);
              formData.append('address', _address);
              formData.append('memo', _memo);
              if (route.type === 'COMPANY') {
                formData.append('customer', values.customer);
                selectedGarbage.forEach((gb) => {
                  formData.append('garbage', gb.value);
                });
              }

              if (collectionPoint) {
                formData.append('location', collectionPoint.location);
                formData.append('sequence', values.sequence);
                // upload image first
                const listImages = await handleUploadImage(values.image, collectionPoint!.id);
                if (listImages && Array.isArray(listImages) && listImages.length >= 0) {
                  const deletedImages = collectionPoint?.images?.filter((img: any) => {
                    const validateImage = listImages.find((imgItem) => {
                      if (!img?.id) {
                        return false;
                      }
                      return imgItem?.value?.id === img?.id;
                    });
                    if (!validateImage) {
                      return true;
                    }
                    return false;
                  });
                  if (deletedImages && deletedImages.length > 0) {
                    const joinDeleteImages: Array<any> = deletedImages.map((img: any) => img?.id);
                    formData.append('remove_images', joinDeleteImages.join(','));
                  }
                }
                await editCollectionPoint(collectionPoint!.id, formData);
                toast({
                  title: '更新された収集ポイント',
                  description: '',
                  status: 'success',
                });
              } else {
                const _sequence = 0;
                const { longitude, latitude } = marker;
                const _location = latitude + ',' + longitude;
                formData.append('location', _location);
                formData.append('route', baseRouteId.toString());
                formData.append('sequence', _sequence.toString());
                const r = await saveCollectionPoint(formData);
                await handleUploadImage(values.image, r?.id);
                if (r) {
                  setHighlightMark(r);
                }
                toast({
                  title: '収集ポイントを作成しました',
                  description: '',
                  status: 'success',
                  duration: 1000,
                });
              }
              handleFetchUpdatedBaseRoute(baseRouteId);
              onClose();
            } catch (e) {
              toast({
                title: 'エラー',
                description: 'ログイン情報をご確認ください。',
                status: 'error',
              });
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {

            const customerOptions = customerList.map((customer: IGarbageCompany) => ({
              value: customer.id,
              label: customer.description || customer.name,
            }));
            // setSelectedCustomer(customerOptions.find((customer) => customer.value === values.customer));
            const handleSetCustomer = (e) => {
              setSelectedCustomer(e);
              values.customer = e.value;
            };
            console.log(values);
            return (
            <form onSubmit={handleSubmit}>
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel
                    htmlFor='upload-file'
                    color='table.headerColor'
                    fontSize='14px'
                    fontWeight='500'
                  >
                    収集ポイント写真
                  </FormLabel>
                  {
                    isOnlyView === false && (
                      <Box marginY={2}>
                        <Button
                          variant='outline'
                          borderRadius='27px'
                          color='sidebar.background'
                          fontSize='13px'
                          fontWeight='400'
                          width='129px'
                          height='33px'
                          onClick={() => imageInputRef.current?.click()}
                          disabled={isSubmitting || values.image.length === 5 || isOnlyView === true}
                        >
                          写真を選択{' '}
                          <AiOutlineCamera
                            style={{
                              height: 18,
                              width: 18,
                              color: '#6F85A3',
                              fontWeight: 600,
                              marginLeft: 10,
                            }}
                          />
                        </Button>
                        <input
                          style={{ display: 'none' }}
                          ref={imageInputRef}
                          type='file'
                          accept='image/*'
                          name='file'
                          id='upload-file'
                          onChange={(event?: HTMLInputEvent) => {
                            if (values.image && Array.isArray(values.image) && values.image.length < 5) {
                              const newImages = [...values.image, event?.target?.files[0]];
                              setFieldValue('image', newImages);
                              if (slideRef && slideRef?.current && slideRef?.current?.slickGoTo) {
                                slideRef?.current?.slickGoTo(newImages.length - 1);
                              }
                            }
                          }}
                          disabled={isSubmitting || values.image.length === 5}
                        ></input>
                      </Box>
                    )
                  }
                  <Box marginTop='12px' marginBottom="20px">
                    {
                      (values.image && Array.isArray(values.image) && values.image.length > 0) && (

                        <Slider {...settings} ref={slideRef}>
                          {(values.image).map((img: any, index) => {
                            return (
                              <Box width="100%" height="237.5px" position='relative' key={index}>
                                <Image
                                  cursor='pointer'
                                  border='1px #3182ce solid'
                                  // onClick={() => imageInputRef.current?.click()}
                                  boxSize='150px'
                                  objectFit='contain'
                                  src={
                                    (typeof img === 'object' && img?.id)
                                      ? img.url
                                      : URL.createObjectURL(img)
                                  }
                                  alt='image'
                                  width="100%"
                                  height="100%"
                                />
                                {
                                  isOnlyView === false && (
                                    <Button
                                      position='absolute'
                                      variant='unstyled'
                                      size='lg'
                                      width="auto"
                                      height="auto"
                                      minWidth="auto"
                                      top="5px"
                                      right="5px"
                                      onClick={() => {
                                        if (values?.image[index]) {
                                          const newImages = values.image.filter((imgItem, indexItem) => {
                                            return indexItem !== index;
                                          });
                                          setFieldValue('image', newImages);
                                        };
                                      }}
                                    >
                                      <MdCancel color="gray" />
                                    </Button>
                                  )
                                }
                              </Box>
                            );
                          })}
                        </Slider>

                      )
                    }
                  </Box>
                </FormControl>
                <FormControl isInvalid={!!(errors.name && touched.name)}>
                  {!!collectionPoint && (
                    <FormControl
                      isInvalid={!!(errors.sequence && touched.sequence)}
                    >
                      <FormLabel
                        htmlFor='sequence'
                        color='table.headerColor'
                        fontSize='14px'
                        fontWeight='500'
                      >
                        收集順番
                      </FormLabel>
                      <InputGroup marginY={2}>
                        <Input
                          readOnly={isOnlyView}
                          value={values.sequence}
                          type='number'
                          name='sequence'
                          onChange={(e) => {
                            const newSequence = parseInt(e.target.value);

                            if (typeof newSequence !== 'number') {
                              return;
                            }

                            if (
                              newSequence < 0 ||
                              newSequence > props.maxSequence
                            ) {
                              if (!toastRef.current) {
                                toastRef.current = toast({
                                  description: `1〜${props.maxSequence} の範囲で入力してください。`,
                                  status: 'error',
                                  duration: 1000,
                                  onCloseComplete: () => {
                                    toastRef.current = null;
                                  }
                                });
                              }
                              return;
                            }

                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          placeholder='順序'
                          size='md'
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors.memo}</FormErrorMessage>
                    </FormControl>
                  )}

                  <FormLabel
                    htmlFor='name'
                    color='table.headerColor'
                    fontSize='14px'
                    fontWeight='500'
                  >
                    集積所名
                  </FormLabel>
                  <InputGroup marginY={2}>
                    <Input
                      value={values.name}
                      type='name'
                      name='name'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='集積所名'
                      size='md'
                      readOnly={isOnlyView}
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!(errors.address && touched.address)}>
                  <FormLabel
                    htmlFor='address'
                    color='table.headerColor'
                    fontSize='14px'
                    fontWeight='500'
                  >
                    収集用ポイントアドレス
                  </FormLabel>
                  <InputGroup marginY={2}>
                    <Input
                      value={values.address}
                      type='address'
                      name='address'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='住所を入力'
                      size='md'
                      readOnly={isOnlyView}
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.address}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!(errors.memo && touched.memo)}>
                  <FormLabel
                    htmlFor='memo'
                    color='table.headerColor'
                    fontSize='14px'
                    fontWeight='500'
                  >
                    収集用ポイントメモ
                  </FormLabel>
                  <InputGroup marginY={2}>
                    <Input
                      value={values.memo}
                      type='memo'
                      name='memo'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='メモを入力'
                      size='md'
                      readOnly={isOnlyView}
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.memo}</FormErrorMessage>
                </FormControl>
                <InputGroup
                  display={route?.type === 'COMPANY' ? 'block' : 'none'}
                  marginBottom='2'
                >
                  <FormControl id='customer' {...(isOnlyView ? { className: style.selectCustomerContainer } : {})}>
                    <Field name='customer'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.customer && form.touched.customer
                          }
                        >
                          <FormLabel
                            htmlFor='customer'
                            color='table.headerColor'
                            fontSize='14px'
                            fontWeight='500'
                          >
                            顧客
                          </FormLabel>

                          <ReactSelect
                            placeholder='顧客を選択'
                            id='customer'
                            name='customer'
                            disabled={isOnlyView}
                            options={customerOptions}
                            onChange={handleSetCustomer}
                            value={selectedCustomer}
                          >
                          </ReactSelect>
                          <FormErrorMessage>
                            {form.errors.customer}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </FormControl>
                </InputGroup>
                <InputGroup
                  display={route?.type === 'COMPANY' ? 'block' : 'none'}
                >
                  <FormControl id='garbage'>
                    <Field name='garbage'>
                      {({ field, form }: { field: any; form: any }) => {
                        return (
                          <FormControl
                            isInvalid={
                              form.errors.garbage
                            }
                          >
                            <FormLabel
                              htmlFor='garbage'
                              color='table.headerColor'
                              fontSize='14px'
                              fontWeight='500'
                            >
                              品目
                            </FormLabel>
                            <MultiSelect
                              {...field}
                              isMulti
                              name='garbage'
                              onChange={handleSetGarbageType}
                              options={garbageList}
                              placeholder='品目を選択'
                              closeMenuOnSelect={false}
                              size='md'
                              value={selectedGarbage}
                              isDisabled={isOnlyView}
                            />
                            <FormErrorMessage>
                              {form.errors.garbage}
                            </FormErrorMessage>
                          </FormControl>
                        );
                      }}
                    </Field>
                  </FormControl>
                </InputGroup>
              </ModalBody>
              {isOnlyView === false && (<ModalFooter justifyContent='space-between'>
                <Button
                  disabled={isSubmitting}
                  onClick={onClose}
                  mr={3}
                  variant='outline'
                  width='134px'
                  borderRadius='36px'
                >
                  キャンセル
                </Button>
                <YellowButton disabled={isSubmitting} type='submit'>
                  {/* 保存 */}
                  {buttonText}
                </YellowButton>
              </ModalFooter>)}
            </form>
          )}}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
