import * as React from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody,
  Button,
} from '@chakra-ui/react';
import { TaskReportTable } from '../../TaskReportTable';
import { ITaskReport } from '../../../models/taskReport';
import { RedButton } from '../../common/RedButton';

interface TaskReportDeleteConfirmationModalProps {
  taskReport?: ITaskReport;
  isOpen: boolean;
  cancelRef: any;
  onCancel: () => void;
  onAccept: () => void;
}

export const TaskReportDeleteConfirmationModal = (
  props: TaskReportDeleteConfirmationModalProps
) => {
  const { isOpen, cancelRef, onCancel, onAccept, taskReport } = props;

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            レポートを削除してもよろしいですか。
          </AlertDialogHeader>
          <AlertDialogBody>
            この機能は元に戻せません。
            <TaskReportTable taskReport={taskReport} />
          </AlertDialogBody>
          <AlertDialogFooter justifyContent="space-between" alignItems='center' flexDirection='column'>
            <RedButton
              onClick={onAccept}
            >
              削除
            </RedButton>
            <Button
              variant='outline'
              ref={cancelRef}
              onClick={onCancel}
              style={{
                borderRadius: '36px',
                boxShadow: 'none'
              }}
              borderColor="sidebar.selectedTextColor"
              color="table.headerColor"
            >
              キャンセル
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
