import React, { useState, useEffect, useMemo, useRef } from 'react';
import { GOOGLE_MAPS_API_TOKEN } from '../../../../constants/mapbox';
import {
  Container,
  VStack,
  Button,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  HStack,
  Text,
} from '@chakra-ui/react';
import './styles.css';
import { IBaseRoute } from '../../../../models/baseRoute';
import { ICollectionPoint } from '../../../../models/collectionPoint';
import { ICourse } from '../../../../models/course';
import CustomMarker from '../Marker';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import SearchMarkerIcon from '../../../../assets/marker-red.png';
import { MapSearchbar } from '../../../MapSearchBar';
import { CourseListContainer } from '../CourseListContainer';
import { CollectionPointListContainer } from '../CollectionPointListContainer';
import { UndoDeletedCourse } from '../UndoDeletedCourse';
import { EditCourseNameModal } from '../EditCourseNameModal';
import mapTypeImage from '../../../../assets/googleMapType.png';
import { AiOutlineLeft } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import GreenMarker from '../../../../assets/GreenMarker.png';
import InfoWindow from '../../../CreateTaskRoute/components/TaskRouteMap/InforWindow';
import { formatCollectionPointSequence } from '../../../../utils/numbers';

interface RouteMapProps {
  baseRoute: IBaseRoute;
  tempMarker: any;
  setTempMarker: (state: any) => void;
  setAddCPModalOpen: (state: boolean) => void;
  updateCollectionPointCoordinates: (
    cp: ICollectionPoint,
    newCoordinates: any
  ) => void;
  courses: ICourse[];
  onDeleteCourse: (state: any) => void;
  onSelectCourse: (state: any) => void;
  onAddNewCourse: () => void;
  cps: ICollectionPoint[];
  onEditCps: (state: any) => void;
  onDeleteCps: (state: any) => void;
  onDragCpsEnd: (state: any) => void;
  undoCollectionPointAction: string | null;
  setUndoCollectionPointAction: (state: any) => void;
  onUndoDragCollectionPointAction: () => void;
  onCloseUndoCollectionPointPopup: () => void;
  onUndoDeleteCollectionPoint: () => void;
  onUndoMarker: () => void;
}

const RouteMap = (props: RouteMapProps) => {
  const mapRef = useRef();
  const history = useHistory();
  const {
    baseRoute,
    tempMarker,
    setAddCPModalOpen,
    setTempMarker,
    updateCollectionPointCoordinates,
    courses,
    setCourses,
    onDeleteCourse,
    onSelectCourse,
    onAddNewCourse,
    cps,
    onDeleteCps,
    onEditCps,
    onDragCpsEnd,
    undoCollectionPointAction,
    onUndoDragCollectionPointAction,
    onCloseUndoCollectionPointPopup,
    onUndoDeleteCollectionPoint,
    onUndoMarker,
    viewport,
    setViewport,
    highlightMark,
    setHighlightMark,
    onOpenModalEditRoute,
    handleOpenViewOnlyCPModal,
  } = props;
  const [markers, setMarkers] = useState<any>([]);
  const [searchedMarker, setSearchedMarker] = useState<any>(null);
  const [google, setGoogle] = useState();
  // const [deletedCp, setDeletedCp] = useState(null);
  const [mapType, setMapType] = useState('roadmap');
  const [editingCourse, setEditingCourse] = useState(null);

  const [isOpenInfoWindow, setIsOpenInfoWindow] = useState(false);

  useEffect(() => {
    if (highlightMark && google && !isOpenInfoWindow) {
      setIsOpenInfoWindow(true);
    }
  }, [highlightMark, google]);

  useEffect(() => {
    if (!baseRoute) return;
    const _markers: any[] = [];

    // const collectionsPoints = baseRoute?.collection_point.sort(
    //   (a: ICollectionPoint, b: ICollectionPoint) => {
    //     return a.sequence - b.sequence;
    //   }
    // );

    cps?.forEach((cp: ICollectionPoint) => {
      const [lat, lng] = cp.location.split(',');
      const _marker = {
        longitude: Number(lng),
        latitude: Number(lat),
        cp,
      };
      _markers.push(_marker);
    });
    setMarkers(_markers);
  }, [baseRoute, cps]);

  const add = (props, marker, e) => {
    setAddCPModalOpen(true);
  };


  const markersView = useMemo(() => {
    return markers.map((marker: any, index: number) => (
      <CustomMarker
        updateCollectionPointCoordinates={updateCollectionPointCoordinates}
        key={`marker-${index}-${marker.cp.id}`}
        index={index}
        marker={marker}
        setViewport={setViewport}
        viewport={viewport}
        highlightMark={highlightMark}
        setHighlightMark={setHighlightMark}
        setIsOpenInfoWindow={setIsOpenInfoWindow}
      />
    ));
  }, [markers, updateCollectionPointCoordinates]);

  const searchedMarkerView = useMemo(() => {
    if (!google || !searchedMarker) return null;

    return (
      <Marker
        position={{ lat: searchedMarker.lat, lng: searchedMarker.lng }}
        icon={{
          url: SearchMarkerIcon,
          scaledSize: new google.maps.Size(35, 50),
        }}
      />
    );
  }, [searchedMarker]);

  const onEditCourse = (_edittingCourse) => {
    setEditingCourse(_edittingCourse);
  };

  const tempMarkerUpdate = (props: any, marker: any, e: any) => {
    setTempMarker({
      longitude: e.latLng.lng(),
      latitude: e.latLng.lat(),
    });
    setViewport({
      longitude: e.latLng.lng(),
      latitude: e.latLng.lat(),
      zoom: viewport.zoom,
    });
  };

  const infoWindowDetail = useMemo(() => {
    if (!google) {
      return null;
    }
    let visibleInfowindow = false;

    const findHighlightMark = markers?.find((r) => {
      return `${r?.cp?.id}` === `${highlightMark?.id}`;
    });

    if (findHighlightMark) {
      visibleInfowindow = true;
    } else {
      visibleInfowindow = false;
    }

    const lagLng = findHighlightMark;
    return (
      <InfoWindow
        visible={visibleInfowindow}
        position={{
          lat: Number(lagLng?.latitude),
          lng: Number(lagLng?.longitude)
        }}
        pixelOffset={{
          height: -38,
          width: 0,
        }}
        shouldFocus={false}
        ariaLabel={'nextInforWindowDetail'}
        onClick={() => {
          onEditCps(highlightMark);
        }}
        google={google}
      >
        <VStack
          align='stretch'
          p={1}
          paddingX={0}
          flex={1}
          alignItems={'center'}
        >
          <HStack minWidth={100}>
            <Text color='black' fontWeight='bold' flexGrow={0} fontSize={16}>
              {formatCollectionPointSequence(highlightMark?.sequence)}
            </Text>
            <Text flexGrow={0} color='#E4E4E4' fontWeight='bold' fontSize={16}>
              |
            </Text>
            <Text
              fontWeight='bold'
              flexGrow={1}
              textAlign='center'
              color="#2E5FA3"
              cursor={'pointer'}
            >
              ポイント編集
            </Text>
          </HStack>
        </VStack>
      </InfoWindow>
    );


  }, [highlightMark, google, markers]);

  const tempInfoWindowDetail = useMemo(() => {
    if (!google) {
      return null;
    }
    let visibleInfowindow = false;

    if (tempMarker) {
      visibleInfowindow = true;
    } else {
      visibleInfowindow = false;
    }

    return (
      <InfoWindow
        visible={visibleInfowindow}
        position={{
          lat: tempMarker?.latitude,
          lng: tempMarker?.longitude
        }}
        pixelOffset={{
          height: -38,
          width: 0,
        }}
        shouldFocus={false}
        ariaLabel={'nextInforWindowDetail'}
        onClick={add}
        google={google}
      >
        <VStack
          align='stretch'
          p={1}
          paddingX={0}
          flex={1}
          alignItems={'center'}
        >
          <HStack minWidth={100}>
            <Text color='black' fontWeight='bold' flexGrow={0} fontSize={16}>
              {formatCollectionPointSequence(markers.length + 1)}
            </Text>
            <Text flexGrow={0} color='#E4E4E4' fontWeight='bold' fontSize={16}>
              |
            </Text>
            <Text
              fontWeight='bold'
              flexGrow={1}
              textAlign='center'
              color="#2E5FA3"
              cursor={'pointer'}
            >
              ポイント追加
            </Text>
          </HStack>
        </VStack>
      </InfoWindow>
    );


  }, [tempMarker, google, markers]);

  return (
    <Container
      position='relative'
      height='100%'
      width='100%'
      maxW='unset'
      m={0}
      p={0}
    >
      <GoogleProvider onChange={(google: any) => setGoogle(google)} />
      <div
        style={{
          position: 'absolute',
          top: '10px',
          // right: '16px',
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          bg='#2E5FA3'
          w='400px'
          p={4}
          color='white'
          marginLeft={15}
          borderRadius='18px'
          display='flex'
        >
          <Button
            width='48px'
            height='48px'
            borderRadius='50%'
            fontSize='16px'
            variant='outline'
            backgroundColor='white'
            border='none'
            color='sidebar.background'
            onClick={() => {
              history.push('/base-routes/list');
            }}
            marginRight='14px'
            flexShrink={0}
          >
            <AiOutlineLeft size={25} />
          </Button>
          <Box textAlign={'left'} flexShrink={1}>
            <Text fontSize={'12px'} color='#C6D5EB'>
              ルート名
            </Text>
            <Text fontSize='15px' fontWeight={500} marginBottom='16px'>
              {baseRoute?.name}
            </Text>
            {baseRoute?.customer?.name && (
              <>
                <Text fontSize={'12px'} color='#C6D5EB'>
                  顧客
                </Text>
                <Text fontSize='15px' fontWeight={500} marginBottom='16px'>
                  {baseRoute?.customer?.name}
                </Text>
              </>
            )}
            {baseRoute?.garbage && baseRoute?.garbage.length > 0 && (
              <>
                <Text fontSize={'12px'} color='#C6D5EB'>
                  品目
                </Text>
                <Text fontSize='15px' fontWeight={500}>
                  {baseRoute?.garbage
                    .map((gb) => {
                      return gb?.name;
                    })
                    .join('/')}
                </Text>
              </>
            )}
            <Button
              backgroundColor={'#3C7DD6'}
              _hover=''
              marginTop={'36px'}
              borderRadius='44px'
              fontSize={'12px'}
              fontWeight='400'
              onClick={onOpenModalEditRoute}
            >
              ルート情報を編集
            </Button>
          </Box>
        </Box>
      </div>
      <MapSearchbar
        onSelectLocation={(location) => {
          setViewport({
            latitude: location.lat,
            longitude: location.lng,
            zoom: 12,
          });
          setSearchedMarker(location);
        }}
        style={{
          height: '52px',
        }}
      />
      <EditCourseNameModal
        isVisible={!!editingCourse}
        setVisible={() => {
          setEditingCourse(null);
        }}
        editingCourse={editingCourse}
        onEditSuccess={(id, newName) => {
          const newCourses = courses.map((e) => {
            if (e.id === id) {
              return {
                ...e,
                name: newName,
              };
            }

            return e;
          });

          setCourses(newCourses);
        }}
      />
      {!!undoCollectionPointAction && (
        <UndoDeletedCourse
          onClose={onCloseUndoCollectionPointPopup}
          onUndo={() => {
            if (undoCollectionPointAction === 'drag') {
              onUndoDragCollectionPointAction?.();
            }

            if (undoCollectionPointAction === 'delete') {
              onUndoDeleteCollectionPoint?.();
            }

            if (undoCollectionPointAction === 'marker') {
              onUndoMarker?.();
            }
          }}
        />
      )}

      {google && <Map
        ref={mapRef}
        google={google}
        mapType='roadmap'
        initialCenter={{ lat: viewport.latitude, lng: viewport.longitude }}
        center={{ lat: viewport.latitude, lng: viewport.longitude }}
        zoom={viewport.zoom}
        mapTypeControl={false}
        zoomControl={false}
        fullscreenControl={false}
        rotateControl={false}
        panControl={false}
        streetViewControl={false}
        onMaptypeidChanged={(map, state) => {
          const { mapTypeId } = state;
          setMapType(
            ['hybrid', 'satellite'].includes(mapTypeId)
              ? 'satellite'
              : 'roadmap'
          );
        }}
        onClick={tempMarkerUpdate}
      >
        {markersView}
        {searchedMarkerView}
        {infoWindowDetail}
        {tempMarker && google && (
          <Marker
            {...props}
            position={{ lat: tempMarker.latitude, lng: tempMarker.longitude }}
            onDragend={tempMarkerUpdate}
            draggable={false}
            title='New'
            name='New'
            // label='N'
            icon={{
              url: GreenMarker,
              // anchor: new google.maps.Point(10, 10),
              scaledSize: new google.maps.Size(35, 50),
            }}
            onClick={add}
          />
        )}
        {tempInfoWindowDetail}
      </Map>}

      <Tabs
        display='flex'
        flexDirection='column'
        overflow='auto'
        w='385px'
        height='100%'
        position='absolute'
        paddingLeft='0'
        right='0'
        background='#fff'
        fontSize='16px'
        fontWeight='bold'
      >
        <TabList
          style={{
            borderBottom: 'none',
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
          }}
        >
          <Tab
            _focus={{ boxShadow: 'none' }}
            style={{
              borderBottomWidth: '5px',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            コース一覧
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none' }}
            style={{
              borderBottomWidth: '5px',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            回収スポット
          </Tab>
        </TabList>
        <TabPanels style={{ paddingLeft: '0' }}>
          <TabPanel style={{ padding: '0' }}>
            <CourseListContainer
              courses={courses}
              onDeleteCourse={(course) => {
                onDeleteCourse?.(course);
              }}
              onSelectCourse={onSelectCourse}
              onAddNewCourse={onAddNewCourse}
              onEditCourse={onEditCourse}
            />
          </TabPanel>
          <TabPanel style={{ padding: '0' }}>
            <CollectionPointListContainer
              cps={cps}
              onDeleteCps={onDeleteCps}
              onEditCps={onEditCps}
              onDragCpsEnd={onDragCpsEnd}
              viewport={viewport}
              setViewport={setViewport}
              highlightMark={highlightMark}
              setHighlightMark={setHighlightMark}
              handleOpenViewOnlyCPModal={handleOpenViewOnlyCPModal}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box
        position='absolute'
        // p={2}
        top={20.5}
        style={{
          left: 620,
        }}
      >
        {/* <VStack>*/}
        {/*  <Button*/}
        {/*    borderRadius={8}*/}
        {/*    width={115}*/}
        {/*    onClick={add}*/}
        {/*    disabled={!tempMarker}*/}
        {/*  >*/}
        {/*    追加33*/}
        {/*  </Button>*/}
        {/* </VStack>*/}
      </Box>

      <Box
        position='absolute'
        left='10px'
        bottom='30px'
        zIndex='10'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <div
          className='map_option_button'
          onClick={() => {
            const { map } = mapRef.current;
            const type = mapType === 'roadmap' ? 'satellite' : 'roadmap';
            setMapType(type);
            map.setMapTypeId(type);
          }}
          style={{
            marginRight: '8px',
            zIndex: 10,
            height: 48,
            width: 48,
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
            border:
              mapType === 'satellite' ? '3px solid #55CA87' : '3px solid white',
          }}
        >
          {/* <FaMap size={25} color={mapType === 'satellite' ? '#fff' : '#333'} /> */}
          <img
            src={mapTypeImage}
            alt='mapType'
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      </Box>
    </Container>
  );
};

function Wrapper(props: any) {
  useEffect(() => {
    props.onChange(props.google);
  }, []);
  return null;
}

const GoogleProvider = GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_TOKEN,
  language: 'ja',
  region: 'JP',
})(Wrapper);

export default RouteMap;
