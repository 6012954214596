import { Box, Container } from '@chakra-ui/layout';
import {
  Table,
  TableContainer,
  Thead,
  Tr,
  Td,
  Tbody,
  Th,
} from '@chakra-ui/table';
import { useDisclosure } from '@chakra-ui/hooks';
import { useToast, Button, Flex, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  getCompanyCustomer,
  IGarbageCompany,
  createCompanyCustomer,
  updateCompanyCustomer,
  deleteCompanyCustomer,
} from '../../services/apiRequests/garbageCompany';
import { CreateCompanyCustomerModal } from './components/CreateCompanyCustomerModal';
import { CustomSpinner } from '../common/Spinner';
import { DeleteCustomerModal } from './components/DeleteCustomerModal';
import DeepBlueButton from '../common/DeepBlueButton';
import CustomCard from '../common/Card';
import { HeadingPage } from '../common/HeadingPage';
import YellowButton from '../common/YellowButton';
import { MdAdd } from 'react-icons/md';
import { PeopleSVG } from '../common/sidebarSVG/people';
import { CustomTd } from '../common/CustomTd';
import { CustomTh } from '../common/CustomTh';
import { CustomTr } from '../common/CustomTr';

export const CompanyCustomer = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [garbageCompany, setGarbageCompany] = useState<Array<IGarbageCompany>>(
    []
  );
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenUpdate,
    onOpen: onOpenUpdate,
    onClose: onCloseUpdate,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState<IGarbageCompany | null>(
    null
  );

  const handleFetchCompanyCustomer = async () => {
    try {
      setLoading(true);
      const results = await getCompanyCustomer();
      if (results) {
        setGarbageCompany(results);
      } else {
        throw new Error('Get Garbage Company Failed');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message,
        description: '',
        status: 'error',
        duration: 2000,
      });
    }
  };

  const handleCreateCompanyCustomer = async (
    body: Array<Omit<IGarbageCompany, 'id'>>,
    callback: Function,
    errorCallback: Function
  ) => {
    try {
      setLoading(true);
      const results = await createCompanyCustomer(body);
      setLoading(false);
      if (!results.error) {
        toast({
          title: '新しい顧客を追加しました',
          description: '',
          status: 'success',
          duration: 2000,
        });
        if (callback) {
          callback();
        }
        handleFetchCompanyCustomer();
      } else {
        if (errorCallback) {
          const { error } = results;
          errorCallback(error);
        }
        throw new Error('新しい顧客を追加できませんでした');
      }
    } catch (err) {
      setLoading(false);
      toast({
        title: err.message,
        description: '',
        status: 'error',
        duration: 2000,
      });
    }
  };

  const handleUpdateCompanyCustomer = async (
    id: string,
    body: Array<Omit<IGarbageCompany, 'id'>>,
    callback: Function,
    errorCallback: Function
  ) => {
    try {
      setLoading(true);
      const results = await updateCompanyCustomer(id, body);
      setLoading(false);
      if (!results.error) {
        toast({
          title: '顧客を編集しました',
          description: '',
          status: 'success',
          duration: 2000,
        });
        if (callback) {
          callback();
        }
        handleFetchCompanyCustomer();
      } else {
        if (errorCallback) {
          const { error } = results;
          errorCallback(error);
        }
        throw new Error('新しい顧客を追加できませんでした');
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message,
        description: '',
        status: 'error',
        duration: 2000,
      });
    }
  };

  const handleDeleteCustomer = async (id: string, callback: Function) => {
    try {
      setLoading(true);
      const results = await deleteCompanyCustomer(id);
      setLoading(false);
      toast({
        title: '顧客を削除しました',
        description: '',
        status: 'success',
        duration: 2000,
      });
      if (callback) {
        callback();
      }
      handleFetchCompanyCustomer();
    } catch (error) {
      setLoading(false);
      toast({
        title: '顧客を削除できませんでした',
        description: '',
        status: 'error',
        duration: 2000,
      });
    }
  };

  useEffect(() => {
    handleFetchCompanyCustomer();
  }, []);

  return (
    <div>
      <Container maxW='container.xl' paddingLeft="0px" paddingRight="0px">
        <Flex align='end' justify='start' mb={4}>
          <PeopleSVG
            color='#2E5FA3'
            size='26'
            style={{ marginRight: 8 }}
          />
          <HeadingPage>事業系 御客一覧</HeadingPage>
        </Flex>
      </Container>
      {isLoading && <CustomSpinner />}
      <CustomCard>
        <Flex justify='end'>
          <YellowButton textAlign='right' onClick={onOpen}>
            <Flex width='100%' height='100%' align='center' justify={'center'}>
              新規作成
              <MdAdd
                size={24}
                style={{
                  marginLeft: 8,
                }}
              />
            </Flex>
          </YellowButton>
        </Flex>

        <TableContainer>
          <Table variant='simple' colorScheme='gray'>
            <Thead borderBottom='1px solid #E4E4E4'>
              <Tr>
                <CustomTh>
                  ID
                </CustomTh>
                <CustomTh>
                  名前
                </CustomTh>
                <CustomTh>
                  説明
                </CustomTh>
                <CustomTh>
                  操作
                </CustomTh>
              </Tr>
            </Thead>
            <Tbody>
              {garbageCompany &&
                Array.isArray(garbageCompany) &&
                garbageCompany.map((companyInfo) => {
                  return (
                    <CustomTr key={companyInfo.id}>
                      <CustomTd
                        color='table.dataColor'
                        fontSize='14px'
                        fontWeight='500'
                      >
                        {companyInfo?.id}
                      </CustomTd>
                      <CustomTd
                        color='table.dataColor'
                        fontSize='14px'
                        fontWeight='500'
                      >
                        {companyInfo?.name}
                      </CustomTd>
                      <CustomTd
                        color='table.dataColor'
                        fontSize='14px'
                        fontWeight='500'
                      >
                        {companyInfo?.description}
                      </CustomTd>
                      <CustomTd>
                        <Box>
                          <DeepBlueButton
                            mr={2}
                            onClick={() => {
                              setSelectedItem(companyInfo);
                              onOpenUpdate();
                            }}
                          >
                            編集
                          </DeepBlueButton>
                          <Button
                            variant='outline'
                            onClick={() => {
                              setSelectedItem(companyInfo);
                              onOpenDelete();
                            }}
                          >
                            削除
                          </Button>
                        </Box>
                      </CustomTd>
                    </CustomTr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </CustomCard>
      <CreateCompanyCustomerModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleCreateCompanyCustomer}
      />
      {selectedItem && (
        <CreateCompanyCustomerModal
          isOpen={isOpenUpdate}
          onClose={() => {
            setSelectedItem(null);
            onCloseUpdate();
          }}
          companyDetails={selectedItem}
          onSubmit={handleUpdateCompanyCustomer}
        />
      )}
      <DeleteCustomerModal
        isOpen={isOpenDelete}
        onClose={() => {
          setSelectedItem(null);
          onCloseDelete();
        }}
        companyDetails={selectedItem}
        onSubmit={handleDeleteCustomer}
      />
    </div>
  );
};
