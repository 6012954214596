export const COMPANY_NAME = '株式会社フィールドプロテクト';
export const TYPE_COMPANY = 'COMPANY';
export const TYPE_BASE = 'BASE';

export const RANDOM_COLOR = [
  '3FB96D',
  'F4CC61',
  '4980CE',
  '5DBBE8',
  '5ED1B8',
  'AA62D8',
  '9BEB5E',
  'FD7A49',
];
