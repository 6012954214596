import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
  Input,
  Text,
} from '@chakra-ui/react';
import { editCompanyReport } from '../../../../services/apiRequests/companyReport';

import { styles } from './styles';
import CancelModalButton from '../../../common/CancelModalButton';
import DeepBlueButton from '../../../common/DeepBlueButton';
import { CODE, ERROR_CODE } from '../../../../utils/constants';
import { isEmpty } from 'lodash';

type Props = {
  isVisible: boolean;
  setVisible: (reloadData: boolean) => void;
  selectedReport: any;
};

export interface ErrorMessage {
  name?: string;
  code?: string;
}

export const EditReportTypeModal: FC<Props> = ({
  isVisible,
  setVisible,
  selectedReport,
}) => {
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [errors, setErrors] = useState<ErrorMessage>({});

  const reset = useCallback(() => {
    setName(selectedReport?.name || '');
    setDescription(selectedReport?.description || '');
    setErrors({});
    setCode(selectedReport?.code || '');
  }, [selectedReport]);

  useEffect(() => {
    if (isVisible) {
      reset();
    }
  }, [isVisible]);

  const handleAddCompanyReport = useCallback(async () => {
    try {
      const tempErrors: ErrorMessage = {};
      if (!name) {
        tempErrors.name = '名前を入力してください。';
      }
      if (!code) {
        tempErrors.code = ERROR_CODE;
      }
      if (!isEmpty(tempErrors)) {
        setErrors(tempErrors);
        return;
      }
      setLoading(true);
      setErrors({});
      const {
        id,
        isOk,
        errors: errorResponse,
      } = await editCompanyReport(selectedReport.id, {
        name,
        description,
        code,
      });

      if (id !== null && id !== undefined) {
        toast({
          title: '新しいレポート タイプの作成成功',
          description: '',
          status: 'success',
          duration: 2000,
        });
        setVisible(true);
      } else {
        if (!isOk && errorResponse && errorResponse?.code) {
          setErrors((curr) => {
            return {
              ...curr,
              code: errorResponse?.code,
            };
          });
        }
        toast({
          title: '新しいレポート タイプの作成に失敗しました',
          description: '',
          status: 'error',
          duration: 2000,
        });
      }
    } catch (error) {
      toast({
        title: '新しいレポート タイプの作成に失敗しました',
        description: '',
        status: 'error',
        duration: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [name, description, code]);

  return (
    <Modal size='xl' isOpen={isVisible} onClose={() => setVisible(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>レポートの種類を編集</ModalHeader>
        <CancelModalButton />
        <ModalBody>
          <div className={styles.container}>
            <div className={styles.infoContainer}>
              <Text fontWeight='bold' mb={2}>
                名前
              </Text>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='名前'
                maxLength={100}
                mb={errors?.name ? 1 : 4}
                isInvalid={!!errors?.name}
              />
              {errors?.name && (
                <Text color={'red'} mb={2}>
                  {errors?.name}
                </Text>
              )}

              <Text fontWeight='bold' mb={2}>
                説明
              </Text>
              <Input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder='説明'
                maxLength={100}
                mb={4}
              />

              <Text fontWeight='bold' mb={2}>
                {CODE}
              </Text>
              <Input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder={CODE}
                maxLength={30}
                mb={errors?.code ? 1 : 4}
                isInvalid={!!errors?.code}
              />
              {errors?.code && (
                <Text color={'red'} mb={2}>
                  {errors?.code}
                </Text>
              )}
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <DeepBlueButton
            isLoading={isLoading}
            onClick={handleAddCompanyReport}
          >
            更新する
          </DeepBlueButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
